<!--table组件-->
<template>
  <div id="SuperTable" :class="{'noTableborder':!hasTableborder,'hasTableborder':hasTableborder}">
    <!--表格-->
    <div id="table" :class="tableClass" v-loading="loading" element-loading-text="加载中..."
         element-loading-spinner="el-icon-loading">
      <el-table-bar  :native="ruleValidate(tableData.titleData,'fixed') || property.fixed">
        <el-table :height="tabHeight" :data="tableData.listData" :header-cell-style="property.headerCellStyle?property.headerCellStyle:headerCellStyle" :row-key="rowKey"
                  :stripe="attribute.attribute" :border="attribute.border" :size="attribute.size" :fit="attribute.fit"
                  :show-header="attribute.showHeader" :highlight-current-row="attribute.highlightCurrentRow"
                  :empty-text="property.emptyText" :row-style="property.rowStyle" :cell-style="property.cellStyle"
                  :row-class-name="property.rowClassName" :cell-class-name="property.cellClassName"
                  :span-method="property.spanMethod" :lazy="property.lazy"  :tree-props="property.treeprops"
                  :load="load" :show-summary='property.showsummary'
                  :class="{scrollbar:ruleValidate(tableData.titleData,'fixed')}" 
                  @cell-click="cellClick" @row-click="rowClick" @header-click="headerClick"
                  @header-dragend="headerDragend"
                  @sort-change="sortChange"
                  @selection-change="selectionChange"
                  @select="selectChange"
                  @select-all="selectAllChange"
                  @current-change="handleCurrentRowChange"
                  style="width: 100%" 
                  ref="mytable">
          <!--表头-->
          <!--多选-->
          <el-table-column
            v-if="tableData && tableData.titleData && tableData.titleData.length>0 && property.isCheckBox"
            type="selection" width="55" align="center" fixed="left" key="1"
            :reserve-selection="attribute.reserveSelection"
            :selectable="tableData.selectable"/>
          <!--序号-->

          <el-table-column
            v-if="tableData && tableData.titleData && tableData.titleData.length>0 && property.isRadio"
            :width="property.radioWidth || '55'"
            align="center"
            fixed="left"
            :label="property.radioLabel"
          >
            <template slot-scope="scope">
              <el-radio
                v-model="radioId"
                v-if="tableData.showRadio(scope.row, scope.$index)"
                :label="scope.row.radioId"
                @change="radioChange(scope)"
              />
            </template>
          </el-table-column>

          <el-table-column v-if="tableData && tableData.titleData && tableData.titleData.length>0 && attribute.isOrder"
                      type="index" label="序号" :index="property.indexMethod || indexMethod" width="55" align="center" key="2" fixed="left"/>
          <!--动态-->
          <template v-for="(item,index) in tableData.titleData">
            <el-table-column v-if="item.showhide" :label="item.label" :prop="item.prop" :width="item.width"
                            :key="item.fileType||item.prop" :min-width="item.minwidth" :fixed="item.fixed" :sortable="item.sortable"
                            :resizable="item.resizable" :show-overflow-tooltip="item.showoverflowtooltip"
                            :align="item.align" :header-align="item.headeralign">
                            <template slot="header" v-if="item.customHeader">
                              <div class="customHeader">
                                <span class="label">{{item.label}}</span>
                                <span class="customContent" @click="customHeaderClick(item)">{{item.customHeaderContent}}</span>
                              </div>
                            </template>
              <!--一级复合表头-->
              <template v-if="item.children">
                <template v-for="(list2,index) in item.children">
                  <el-table-column v-if="list2.showhide" :label="list2.label" :prop="list2.prop" :width="list2.width"
                                  :key="list2.fileType||list2.prop"
                                  :min-width="list2.minwidth" :fixed="list2.fixed" :sortable="list2.sortable"
                                  :resizable="list2.resizable" :show-overflow-tooltip="list2.showoverflowtooltip"
                                  :align="list2.align" :header-align="list2.headeralign">
                    <!--二级复合表头-->
                    <template v-if="list2.children">
                      <template v-for="(list3,index) in list2.children">
                        <el-table-column v-if="list3.showhide" :label="list3.label" :prop="list3.prop"
                                        :width="list3.width"
                                        :key="list3.fileType||list3.prop"
                                        :min-width="list3.minwidth" :fixed="list3.fixed" :sortable="list3.sortable"
                                        :resizable="list3.resizable" :show-overflow-tooltip="list3.showoverflowtooltip"
                                        :align="list3.align" :header-align="list3.headeralign">
                          <!--三级复合表头-->
                          <template v-if="list3.children">
                            <template v-for="(list4,index) in list3.children">
                              <el-table-column v-if="list4.showhide" :label="list4.label" :prop="list4.prop"
                                              :width="list4.width"
                                              :key="list4.fileType||list4.prop"
                                              :min-width="list4.minwidth" :fixed="list4.fixed" :sortable="list4.sortable"
                                              :resizable="list4.resizable"
                                              :show-overflow-tooltip="list4.showoverflowtooltip"
                                              :align="list4.align" :header-align="list4.headeralign">
                                <template v-if="list4.children">
                                  <template v-for="(list5,index) in list4.children">
                                    <el-table-column v-if="list5.showhide" :label="list5.label" :prop="list5.prop"
                                                    :width="list5.width"
                                                    :key="list5.fileType||list5.prop"
                                                    :min-width="list5.minwidth" :fixed="list5.fixed"
                                                    :sortable="list5.sortable"
                                                    :resizable="list5.resizable"
                                                    :show-overflow-tooltip="list5.showoverflowtooltip"
                                                    :align="list5.align" :header-align="list5.headeralign">

                                    </el-table-column>
                                  </template>
                                </template>
                                <template v-if="!list4.children" slot-scope="scope">
                                  <template v-if="list4.type">
                                    <!--图片类型-->
                                    <template v-if="list4.type == 'img'">
                                      <ul style="display: flex">
                                        <template v-for="imglist in scope.row[list4.prop]">
                                          <li style="flex: 1;margin: 0 5px">>
                                            <img :src="imglist" style="width: 100%;height: 100%;">
                                          </li>
                                        </template>
                                      </ul>
                                    </template>
                                    <!--字体图标类型-->
                                    <template v-if="list4.type == 'icon'">
                                      <ul style="display: flex">
                                        <template v-for="iconlist in scope.row[list4.prop]">
                                          <li style="flex: 1;margin: 0 5px">
                                            <i class="iconfont" :class="iconlist"></i>
                                          </li>
                                        </template>
                                      </ul>
                                    </template>
                                    <!--链接类型-->
                                    <template v-if="list4.type == 'link'">
                                      <ul>
                                        <template v-for="linklist in scope.row[list4.prop]">
                                          <li>
                                            <a href="javascript:;" @click="linkFun(linklist)">{{linklist}}</a>
                                          </li>
                                        </template>
                                      </ul>
                                    </template>
                                    <!--文件类型-->
                                    <template v-if="list4.type == 'file'">
                                      <ul>
                                        <template v-for="filelist in scope.row[list4.prop]">
                                          <li @click="downloadFile(filelist)">{{filelist.filename}}</li>
                                        </template>
                                      </ul>
                                    </template>
                                    <!--元素类型-->
                                    <template v-if="list4.type == 'element'">
                                      <span :class="[scope.row[list4.prop] == '离线' ? 'OffLine' : '',scope.row[list4.prop] == '正常' ?'regular' : '',
                                      scope.row[list4.prop] == '异常' ? 'abnormal' : '',scope.row[list4.prop] == '超标' ? 'exceedingStandard' : '']"></span>
                                      <span>{{scope.row[list4.prop]}}</span>
                                    </template>
                                    <!--元素类型块级颜色-->
                                    <div v-if="list4.type == 'elementBlock'" class="colorBlock">
                                      <span v-if="scope.row[list4.prop] == '超标'" class="orangeBlock">{{scope.row[list4.prop]}}</span>
                                      <span v-else-if="scope.row[list4.prop] == '达标'" class="greenBlock">{{scope.row[list4.prop]}}</span>
                                      <span v-else>--</span>
                                    </div>
                                    <!--元素类型块级颜色（对象型）-->
                                    <div v-if="list4.type == 'arrBlock'" class="colorBlock">
                                      <span v-if="scope.row[list4.prop].isOver == '超标'" class="orangeBlock">{{scope.row[list4.prop].monitorValue}}</span>
                                      <span v-else-if="scope.row[list4.prop].isOver == '达标'" class="greenBlock">{{scope.row[list4.prop].monitorValue}}</span>
                                      <span v-else-if="scope.row[list4.prop].isOver == '未超标'">{{scope.row[list4.prop].monitorValue}}</span>
                                      <span v-else>--</span>
                                    </div>
                                    <!--水质类别颜色-->
                                    <div v-if="list4.type == 'genameColor'" class="colorBlock">
                                      <span v-if="scope.row[list4.prop] == '1' || scope.row[list4.prop] == 'Ⅰ类'" class="bgColor1">I类</span>
                                      <span v-else-if="scope.row[list4.prop] == '2' || scope.row[list4.prop] == 'Ⅱ类'" class="bgColor2">II类</span>
                                      <span v-else-if="scope.row[list4.prop] == '3' || scope.row[list4.prop] == 'Ⅲ类'" class="bgColor3">III类</span>
                                      <span v-else-if="scope.row[list4.prop] == '4' || scope.row[list4.prop] == 'Ⅳ类'" class="bgColor4">IV类</span>
                                      <span v-else-if="scope.row[list4.prop] == '5' || scope.row[list4.prop] == 'Ⅴ类'" class="bgColor5">V类</span>
                                      <span v-else-if="scope.row[list4.prop] == '6' || scope.row[list4.prop] == '劣Ⅴ类'" class="bgColor6">劣V类</span>
                                      <span v-else>--</span>
                                    </div>
                                    <!--幅度变化-->
                                    <template v-if="list4.type == 'range'">
                                      <span :class="scope.row[list4.prop][0] == '↑' ? 'red' : 'green' ">{{scope.row[list4.prop][0]}}</span>
                                      <span>{{scope.row[list4.prop].substring(1,7)}}</span>
                                    </template>
                                    <!--箭头-->
                                    <template v-if="list4.type == 'jiantou'">
                                      <span v-if="scope.row[list4.prop] == '↑'" class="green">↑</span>
                                      <span v-else-if="scope.row[list4.prop] == '↓'" class="red">↓</span>
                                      <span v-else>--</span>
                                    </template>
                                    <!--污染物类型-->
                                    <template v-if="list4.type == 'contaminated'">
                                      <template
                                        v-if="typeof(scope.row[list4.prop]) == 'string' || typeof(scope.row[list4.prop]) == 'number'">
                                        <span>{{scope.row[list4.prop]}}</span>
                                      </template>
                                      <template v-else>
                                        <span
                                          v-if="scope.row[list4.prop].value == null ? scope.row[list4.prop].value = '-' : scope.row[list4.prop].value">{{scope.row[list4.prop].value}}</span>
                                      </template>
                                    </template>
                                    <!-- <template v-if="list4.type == 'tableCellEdit'">
                                        <editable-cell
                                          :show-input="item.editMode"
                                          v-model="scope.row[item.prop]"
                                          v-bind="item.elePorp"
                                        >
                                          <span slot="content">{{ scope.row[list4.prop] || scope.row[list4.prop] == 0 ? scope.row[list4.prop] : "--" }}</span>
                                        </editable-cell>
                                    </template> -->
                                    <div v-if="list4.type == 'tableCellEdit'" class="oneInput">
                                      <editable-cell
                                        v-if="!scope.row.hidden"
                                        @inputChange="inputChange"
                                        :show-input="list4.editMode"
                                        v-model="scope.row[list4.prop]"
                                        v-bind="list4.elePorp"
                                      >
                                        <span slot="content" style="padding:0 1px">{{ scope.row[list4.prop] || scope.row[list4.prop] == 0 ? scope.row[list4.prop] : "--" }}</span>
                                      </editable-cell>
                                    </div>
                                    <template v-if="list4.type == 'selection'">
                                      <el-checkbox
                                        v-model="checkList"
                                        :label="scope.row[list4.prop]"
                                        @change="handleCheckChange(scope.row)"
                                        :disabled="scope.row.disabled"
                                        :checked="scope.row.checked"
                                        class="selection"
                                      ></el-checkbox> 
                                    </template>
                                    <template v-if="list4.type == 'slot'">
                                      <slot :name="list4.name" :props="scope.row"></slot>
                                    </template>
                                  </template>
                                  <template v-else>
                                    <!-- <span>{{scope.row[list4.prop] || "--"}}</span> -->
                                    <span>{{scope.row[list4.prop]===""?"--":scope.row[list4.prop]}}</span>
                                  </template>
                                </template>
                              </el-table-column>
                            </template>
                          </template>
                          <template v-if="!list3.children" slot-scope="scope">
                            <template v-if="list3.type">
                              <!--图片类型-->
                              <template v-if="list3.type == 'img'">
                                <ul style="display: flex">
                                  <template v-for="imglist in scope.row[list3.prop]">
                                    <li style="flex: 1;margin: 0 5px">>
                                      <img :src="imglist" style="width: 100%;height: 100%;">
                                    </li>
                                  </template>
                                </ul>
                              </template>
                              <!--字体图标类型-->
                              <template v-if="list3.type == 'icon'">
                                <ul style="display: flex">
                                  <template v-for="iconlist in scope.row[list3.prop]">
                                    <li style="flex: 1;margin: 0 5px">
                                      <i class="iconfont" :class="iconlist"></i>
                                    </li>
                                  </template>
                                </ul>
                              </template>
                              <!--链接类型-->
                              <template v-if="list3.type == 'link'">
                                <ul>
                                  <template v-for="linklist in scope.row[list3.prop]">
                                    <li>
                                      <a href="javascript:;" @click="linkFun(linklist)">{{linklist}}</a>
                                    </li>
                                  </template>
                                </ul>
                              </template>
                              <!--文件类型-->
                              <template v-if="list3.type == 'file'">
                                <ul>
                                  <template v-for="filelist in scope.row[list3.prop]">
                                    <li @click="downloadFile(filelist)">{{filelist.filename}}</li>
                                  </template>
                                </ul>
                              </template>
                              <!--元素类型-->
                              <template v-if="list3.type == 'element'">
                                  <span :class="[scope.row[list3.prop] == '离线' ? 'OffLine' : '',scope.row[list3.prop] == '正常' ?'regular' : '',
                                  scope.row[list3.prop] == '异常' ? 'abnormal' : '',scope.row[list3.prop] == '超标' ? 'exceedingStandard' : '']"></span>
                                <span>{{scope.row[list3.prop]}}</span>
                              </template>
                              <!--元素类型块级颜色-->
                              <div v-if="list3.type == 'elementBlock'" class="colorBlock">
                                <span v-if="scope.row[list3.prop] == '超标'" class="orangeBlock">{{scope.row[list3.prop]}}</span>
                                <span v-else-if="scope.row[list3.prop] == '达标'" class="greenBlock">{{scope.row[list3.prop]}}</span>
                                <span v-else>--</span>
                              </div>
                              <!--元素类型块级颜色（对象型）-->
                              <div v-if="list3.type == 'arrBlock'" class="colorBlock">
                                <span v-if="scope.row[list3.prop].isOver == '超标'" class="orangeBlock">{{scope.row[list3.prop].monitorValue}}</span>
                                <span v-else-if="scope.row[list3.prop].isOver == '达标'" class="greenBlock">{{scope.row[list3.prop].monitorValue}}</span>
                                <span v-else-if="scope.row[list3.prop].isOver == '未超标'">{{scope.row[list3.prop].monitorValue}}</span>
                                <span v-else>--</span>
                              </div>
                              <!--水质类别颜色-->
                              <div v-if="list3.type == 'genameColor'" class="colorBlock">
                                <span v-if="scope.row[list3.prop] == '1' || scope.row[list3.prop] == 'Ⅰ类'" class="bgColor1">I类</span>
                                <span v-else-if="scope.row[list3.prop] == '2' || scope.row[list3.prop] == 'Ⅱ类'" class="bgColor2">II类</span>
                                <span v-else-if="scope.row[list3.prop] == '3' || scope.row[list3.prop] == 'Ⅲ类'" class="bgColor3">III类</span>
                                <span v-else-if="scope.row[list3.prop] == '4' || scope.row[list3.prop] == 'Ⅳ类'" class="bgColor4">IV类</span>
                                <span v-else-if="scope.row[list3.prop] == '5' || scope.row[list3.prop] == 'Ⅴ类'" class="bgColor5">V类</span>
                                <span v-else-if="scope.row[list3.prop] == '6' || scope.row[list3.prop] == '劣Ⅴ类'" class="bgColor6">劣V类</span>
                                <span v-else>--</span>
                              </div>
                              <!--幅度变化-->
                              <template v-if="list3.type == 'range'">
                                <span :class="scope.row[list3.prop][0] == '↑' ? 'red' : 'green' ">{{scope.row[list3.prop][0]}}</span>
                                <span>{{scope.row[list3.prop].substring(1,7)}}</span>
                              </template>
                              <!--箭头-->
                              <template v-if="list3.type == 'jiantou'">
                                <span v-if="scope.row[list3.prop] == '↑'" class="green">↑</span>
                                <span v-else-if="scope.row[list3.prop] == '↓'" class="red">↓</span>
                                <span v-else>--</span>
                              </template>
                              <!--污染物类型-->
                              <template v-if="list3.type == 'contaminated'">
                                <template v-if="typeof(scope.row[list3.prop]) == 'string' || typeof(scope.row[list3.prop]) == 'number'">
                                  <span>{{scope.row[list3.prop]}}</span>
                                </template>
                                <template v-else>
                                  <span
                                    v-if="scope.row[list3.prop].value == null ? scope.row[list3.prop].value = '-' : scope.row[list3.prop].value">{{scope.row[list3.prop].value}}</span>
                                </template>
                              </template>
                               <!-- 操作按钮 -->
                               <template v-if="list3.type == 'icon_click'">
                                 <span
                                    v-for="list33 in tableData.btnData.filter(({ name }) => scope.row[list3.prop].includes(name))"
                                    class="operation_btn iconfont"
                                    :id="list33.name"
                                    :title="list33.label"
                                    :class="list33.icon"
                                    @click="operationClick(list33, scope,list3)"
                                  >
                                  </span>
                              </template>
                              <!-- <template v-if="list3.type == 'tableCellEdit'">
                                  <editable-cell
                                    :show-input="item.editMode"
                                    v-model="scope.row[item.prop]"
                                    v-bind="item.elePorp"
                                  >
                                    <span slot="content">{{ scope.row[list3.prop] || scope.row[list3.prop] == 0 ? scope.row[list3.prop] : "--" }}</span>
                                  </editable-cell>
                              </template> -->
                              <div v-if="list3.type == 'tableCellEdit'" class="oneInput">
                                <editable-cell
                                  v-if="!scope.row.hidden"
                                  @inputChange="inputChange"
                                  :show-input="list3.editMode"
                                  v-model="scope.row[list3.prop]"
                                  v-bind="list3.elePorp"
                                >
                                  <span slot="content" style="padding:0 1px">{{ scope.row[list3.prop] || scope.row[list3.prop] == 0 ? scope.row[list3.prop] : "--" }}</span>
                                </editable-cell>
                              </div>
                              <template v-if="list3.type == 'selection'">
                                <el-checkbox
                                  v-model="checkList"
                                  :label="scope.row[list3.prop]"
                                  @change="handleCheckChange(scope.row)"
                                  :disabled="scope.row.disabled"
                                  :checked="scope.row.checked"
                                  class="selection"
                                ></el-checkbox> 
                              </template>
                              <template v-if="list3.type == 'slot'">
                                      <slot :name="list3.name" :props="scope.row"></slot>
                                    </template>
                            </template>
                            <template v-else>
                              <!-- <span>{{scope.row[list3.prop] || "--"}}</span> -->
                              <span>{{scope.row[list3.prop]===""?"--":scope.row[list3.prop]}}</span>
                            </template>
                          </template>
                        </el-table-column>
                      </template>
                    </template>
                    <template v-if="!list2.children" slot-scope="scope">
                      <template v-if="list2.type">
                        <!--图片类型-->
                        <template v-if="list2.type == 'img'">
                          <ul style="display: flex">
                            <template v-for="imglist in scope.row[item.prop]">
                              <li style="flex: 1;margin: 0 5px">>
                                <img :src="imglist" style="width: 100%;height: 100%;">
                              </li>
                            </template>
                          </ul>
                        </template>
                        <!--字体图标类型-->
                        <template v-if="list2.type == 'icon'">
                          <ul style="display: flex">
                            <template v-for="iconlist in scope.row[item.prop]">
                              <li style="flex: 1;margin: 0 5px">
                                <i class="iconfont" :class="iconlist"></i>
                              </li>
                            </template>
                          </ul>
                        </template>
                        <!--链接类型-->
                        <template v-if="list2.type == 'link'">
                          <ul>
                            <template v-for="linklist in scope.row[item.prop]">
                              <li>
                                <a href="javascript:;" @click="linkFun(linklist)">{{linklist}}</a>
                              </li>
                            </template>
                          </ul>
                        </template>
                        <!--文件类型-->
                        <template v-if="list2.type == 'file'">
                          <ul>
                            <template v-for="filelist in scope.row[item.prop]">
                              <li @click="downloadFile(filelist)">{{filelist.filename}}</li>
                            </template>
                          </ul>
                        </template>
                        <template v-if="list2.type == 'file1'">
                          <ul>
                            <template v-for="filelist in scope.row[item.prop]">
                              <li @click="downloadFile(filelist)">{{filelist.filename}}</li>
                            </template>
                          </ul>
                        </template>
                        <template v-if="list2.type == 'file2'">
                          <ul>
                            <template v-for="filelist in scope.row[item.prop]">
                              <li @click="downloadFile(filelist)">{{filelist.filename}}</li>
                            </template>
                          </ul>
                        </template>
                        <!--元素类型-->
                        <template v-if="list2.type == 'element'">
                          <span :class="[scope.row[list2.prop] == '离线' ? 'OffLine' : '',scope.row[list2.prop] == '正常' ?'regular' : '',
                          scope.row[list2.prop] == '异常' ? 'abnormal' : '',scope.row[list2.prop] == '超标' ? 'exceedingStandard' : '']"></span>
                          <span>{{scope.row[list2.prop]}}</span>
                        </template>
                        <!--元素类型块级颜色-->
                        <div v-if="list2.type == 'elementBlock'" class="colorBlock">
                          <span v-if="scope.row[list2.prop] == '超标'" class="orangeBlock">{{scope.row[list2.prop]}}</span>
                          <span v-else-if="scope.row[list2.prop] == '达标' || scope.row[list2.prop] == '已复查' || scope.row[list2.prop] == '未通报'" class="greenBlock">{{scope.row[list2.prop]}}</span>
                          <span v-else-if="scope.row[list2.prop] == '未复查' || scope.row[list2.prop] == '已通报'" class="bgColor6">{{scope.row[list2.prop]}}</span>
                          <span v-else>--</span>
                        </div>
                        <!--元素类型块级颜色（对象型）-->
                        <div v-if="list2.type == 'arrBlock'" class="colorBlock">
                          <span v-if="scope.row[list2.prop].isOver == '超标'" class="orangeBlock">{{scope.row[list2.prop].monitorValue}}</span>
                          <span v-else-if="scope.row[list2.prop].isOver == '达标'" class="greenBlock">{{scope.row[list2.prop].monitorValue}}</span>
                          <span v-else-if="scope.row[list2.prop].isOver == '未超标'">{{scope.row[list2.prop].monitorValue}}</span>
                          <span v-else>--</span>
                        </div>
                        <!--水质类别颜色-->
                        <div v-if="list2.type == 'genameColor'" class="colorBlock">
                          <span v-if="scope.row[list2.prop] == '1' || scope.row[list2.prop] == 'Ⅰ类'" class="bgColor1">I类</span>
                          <span v-else-if="scope.row[list2.prop] == '2' || scope.row[list2.prop] == 'Ⅱ类'" class="bgColor2">II类</span>
                          <span v-else-if="scope.row[list2.prop] == '3' || scope.row[list2.prop] == 'Ⅲ类'" class="bgColor3">III类</span>
                          <span v-else-if="scope.row[list2.prop] == '4' || scope.row[list2.prop] == 'Ⅳ类'" class="bgColor4">IV类</span>
                          <span v-else-if="scope.row[list2.prop] == '5' || scope.row[list2.prop] == 'Ⅴ类'" class="bgColor5">V类</span>
                          <span v-else-if="scope.row[list2.prop] == '6' || scope.row[list2.prop] == '劣Ⅴ类'" class="bgColor6">劣V类</span>
                          <span v-else>--</span>
                        </div>
                        <!-- 进度条 -->
                        <div v-if="list2.type == 'progressBar'" class="blockDiv">
                          <div v-if="scope.row[list2.prop] == ''">--</div>
                          <div v-else class="bgDivs">
                            <span>{{scope.row[list2.prop]}}%</span>
                            <div :style="{width: scope.row[list2.prop] + '%' }"></div>
                          </div>
                        </div>
                        <!--幅度变化-->
                        <template v-if="list2.type == 'range'">
                          <span
                            :class="scope.row[list2.prop][0] == '↑' ? 'red' : 'green' ">{{scope.row[list2.prop][0]}}</span>
                          <span>{{scope.row[list2.prop].substring(1,100)}}</span>
                        </template>
                        <!--箭头-->
                        <template v-if="list2.type == 'jiantou'">
                          <span v-if="scope.row[list2.prop] == '↑'" class="green">↑</span>
                          <span v-else-if="scope.row[list2.prop] == '↓'" class="red">↓</span>
                          <span v-else>--</span>
                        </template>
                        <!--污染物类型-->
                        <template v-if="list2.type == 'contaminated'">
                          <template v-if="typeof(scope.row[list2.prop]) == 'string' || typeof(scope.row[list2.prop]) == 'number'">
                            <span>{{scope.row[list2.prop]}}</span>
                          </template>
                          <template v-else>
                            <span
                              v-if="scope.row[list2.prop].value == null ? scope.row[list2.prop].value = '-' : scope.row[list2.prop].value">{{scope.row[list2.prop].value}}</span>
                          </template>
                        </template>
                        <!-- 操作按钮 -->
                        <template v-if="list2.type == 'icon_click'">
                          <span
                            v-for="list22 in tableData.btnData.filter(({ name }) => scope.row[list2.prop].includes(name))"
                            class="operation_btn iconfont"
                            :id="list22.name"
                            :title="list22.label"
                            :class="list22.icon"
                            @click="operationClick(list22, scope,list2)"
                          >
                          </span>
                        </template>
                        <!-- <template v-if="list2.type == 'tableCellEdit'">
                            <editable-cell
                              :show-input="item.editMode"
                              v-model="scope.row[item.prop]"
                              v-bind="item.elePorp"
                            >
                              <span slot="content">{{ scope.row[list2.prop] || scope.row[list2.prop] == 0 ? scope.row[list2.prop] : "--" }}</span>
                            </editable-cell>
                        </template> -->
                        <div v-if="list2.type == 'tableCellEdit'" class="oneInput">
                          <editable-cell
                            v-if="!scope.row.hidden"
                            @inputChange="inputChange"
                            :show-input="list2.editMode"
                            v-model="scope.row[list2.prop]"
                            v-bind="list2.elePorp"
                          >
                            <span slot="content" style="padding:0 1px">{{ scope.row[list2.prop] || scope.row[list2.prop] == 0 ? scope.row[list2.prop] : "--" }}</span>
                          </editable-cell>
                        </div>
                        <template v-if="list2.type == 'selection'">
                          <el-checkbox
                            v-model="checkList"
                            :label="scope.row[list2.prop]"
                            @change="handleCheckChange(scope.row)"
                            :disabled="scope.row.disabled"
                            :checked="scope.row.checked"
                            class="selection"
                          ></el-checkbox> 
                        </template>
                        <template v-if="list2.type == 'slot'">
                          <slot :name="list2.name" :props="scope.row"></slot>
                        </template>
                      </template>
                      <!-- <template v-if="list2.ziduan2"> -->
                        <!-- ziduan1，说明：prop指向的是对象 -->
                        <!-- ziduan2，说明：prop指向的是对象的字段 -->
                        <!-- <span>{{scope.row[list2.ziduan1][list2.ziduan2]===""?"--":scope.row[list2.ziduan1][list2.ziduan2]}}</span> -->
                      <!-- </template> -->
                      <template v-else>
                        <span v-if="list2.prop.includes('.')">
                            <span>{{scope.row[list2.prop.split(".")[0]][list2.prop.split(".")[1]]===""?"--":scope.row[list2.prop.split(".")[0]][list2.prop.split(".")[1]]}}</span>
                        </span>
                        <span v-else>{{scope.row[list2.prop]===""?"--":scope.row[list2.prop]}}</span>
                      </template>
                    </template>
                  </el-table-column>
                </template>
              </template>
              <template v-if="!item.children" slot-scope="scope">
                <template v-if="item.type">
                  <!--图片类型-->
                  <template v-if="item.type == 'img'">
                    <ul style="display: flex">
                      <template v-for="imglist in scope.row[item.prop]">
                        <li style="flex: 1;margin: 0 5px">>
                          <img :src="imglist" style="width: 100%;height: 100%;">
                        </li>
                      </template>
                    </ul>
                  </template>
                  <!--字体图标类型-->
                  <template v-if="item.type == 'icon'">
                    <ul style="display: flex">
                      <template v-for="iconlist in scope.row[item.prop]">
                        <li style="flex: 1;margin: 0 5px">
                          <i class="iconfont" :class="iconlist"></i>
                        </li>
                      </template>
                    </ul>
                  </template>
                  <!--链接类型-->
                  <template v-if="item.type == 'link'">
                    <ul>
                      <template v-for="linklist in scope.row[item.prop]">
                        <li>
                          <a href="javascript:;" @click="linkFun(linklist)">{{linklist}}</a>
                        </li>
                      </template>
                    </ul>
                  </template>
                  <!--链接类型点击文字-->
                  <template v-if="item.type == 'textClick'">
                    <span @click="textClickFun(scope.row,item.prop)" class="hands">{{scope.row[item.prop]}}</span>
                  </template>
                  <!--文件类型-->
                  <template v-if="item.type == 'file'">
                    <ul>
                      <template v-for="filelist in scope.row[item.prop]">
                        <li @click="downloadFile(filelist)">{{filelist.filename}}</li>
                      </template>
                    </ul>
                  </template>
                  <!--元素类型-->
                  <template v-if="item.type == 'element'">
                    <span :class="[scope.row[item.prop] == '离线' ? 'OffLine' : '',scope.row[item.prop] == '正常' ?'regular' : '',
                    scope.row[item.prop] == '异常' ? 'abnormal' : '',scope.row[item.prop] == '超标' ? 'exceedingStandard' : '']"></span>
                    <span>{{scope.row[item.prop]}}</span>
                  </template>
                  <!--元素类型块级颜色-->
                  <div v-if="item.type == 'elementBlock'" class="colorBlock">
                    <span v-if="scope.row[item.prop] == '超标'" class="orangeBlock">{{scope.row[item.prop]}}</span>
                    <span v-else-if="scope.row[item.prop] == '达标' || scope.row[item.prop] == '已复查' || scope.row[item.prop] == '未通报'" class="greenBlock">{{scope.row[item.prop]}}</span>
                    <span v-else-if="scope.row[item.prop] == '未复查' || scope.row[item.prop] == '已通报'" class="bgColor6">{{scope.row[item.prop]}}</span>
                    <span v-else>--</span>
                  </div>
                  <!--元素类型块级颜色（对象型）-->
                  <div v-if="item.type == 'arrBlock'" class="colorBlock">
                    <span v-if="scope.row[item.prop].isOver == '超标'" class="orangeBlock">{{scope.row[item.prop].monitorValue}}</span>
                    <span v-else-if="scope.row[item.prop].isOver == '达标'" class="greenBlock">{{scope.row[item.prop].monitorValue}}</span>
                    <span v-else-if="scope.row[item.prop].isOver == '未超标'">{{scope.row[item.prop].monitorValue}}</span>
                    <span v-else>--</span>
                  </div>
                  <div v-if="item.type == 'arrDiv'">
                    <span v-if="scope.row[item.prop].isEdit == false">{{scope.row[item.prop].myValue || "--"}}</span>
                    <template v-else>
                      <editable-cell
                        @inputChange="inputChange"
                        :show-input="scope.row[item.editMode]"
                        v-model="scope.row[item.prop].myValue"
                      >
                        <!-- style="padding:0 10px"  空字符点击可以编辑-->
                        <span slot="content">{{ scope.row[item.prop].myValue || scope.row[item.prop].myValue == 0 ? scope.row[item.prop].myValue : "--" }}</span>
                      </editable-cell>
                    </template>
                  </div>
                  <!--水质类别颜色-->
                  <div v-if="item.type == 'genameColor'" class="colorBlock">
                    <span v-if="scope.row[item.prop] == '1' || scope.row[item.prop] == 'Ⅰ类'" class="bgColor1">I类</span>
                    <span v-else-if="scope.row[item.prop] == '2' || scope.row[item.prop] == 'Ⅱ类'" class="bgColor2">II类</span>
                    <span v-else-if="scope.row[item.prop] == '3' || scope.row[item.prop] == 'Ⅲ类'" class="bgColor3">III类</span>
                    <span v-else-if="scope.row[item.prop] == '4' || scope.row[item.prop] == 'Ⅳ类'" class="bgColor4">IV类</span>
                    <span v-else-if="scope.row[item.prop] == '5' || scope.row[item.prop] == 'Ⅴ类'" class="bgColor5">V类</span>
                    <span v-else-if="scope.row[item.prop] == '6' || scope.row[item.prop] == '劣Ⅴ类'" class="bgColor6">劣V类</span>
                    <span v-else>--</span>
                  </div>
                  <!--状态颜色-->
                  <div v-if="item.type == 'typeColor'" class="colorBlock">
                    <span v-if="scope.row[item.prop] === '0' || scope.row[item.prop] === 0" class="typeColor0">待审核</span>
                    <span v-else-if="scope.row[item.prop] === '1' || scope.row[item.prop] === 1" class="typeColor1">审核中</span>
                    <span v-else-if="scope.row[item.prop] === '2' || scope.row[item.prop] === 2" class="typeColor2">审核完成</span>
                    <span v-else>--</span>
                  </div>
                  <div v-if="item.type == 'objColor'" class="colorBlock"> 
                    <span v-if="scope.row[item.prop].val === '0' || scope.row[item.prop].val === 0" class="typeColor0">{{scope.row[item.prop].txt}}</span>
                    <span v-else-if="scope.row[item.prop].val === '1' || scope.row[item.prop].val === 1" class="typeColor1">{{scope.row[item.prop].txt}}</span>
                    <span v-else-if="scope.row[item.prop].val === '2' || scope.row[item.prop].val === 2 && (scope.row[item.prop].val2 === '1' || scope.row[item.prop].val2 === 1)" class="typeColor3">{{scope.row[item.prop].txt}}</span>
                    <span v-else-if="scope.row[item.prop].val === '2' || scope.row[item.prop].val === 2" class="typeColor2">{{scope.row[item.prop].txt}}</span>
                    <span v-else>--</span>
                  </div>
                  <div v-if="item.type == 'fillInColor'" class="colorBlock">
                    <span v-if="scope.row[item.prop] === '-1' || scope.row[item.prop] === -1" class="fillInColorNone">未填报</span>
                    <span v-else-if="scope.row[item.prop] === '0' || scope.row[item.prop] === 0" class="fillInColor0">填报中</span>
                    <span v-else-if="scope.row[item.prop] === '1' || scope.row[item.prop] === 1" class="fillInColor1">审核中</span>
                    <span v-else-if="scope.row[item.prop] === '2' || scope.row[item.prop] === 2" class="fillInColor2">已备案</span>
                    <span v-else-if="scope.row[item.prop] === '3' || scope.row[item.prop] === 3" class="fillInColor3">退回修改</span>
                    <span v-else>--</span>
                  </div>
                  <!-- 进度条 -->
                  <div v-if="item.type == 'progressBar'" class="blockDiv">
                    <div v-if="scope.row[item.prop] == ''">--</div>
                    <div v-else class="bgDivs">
                      <span>{{scope.row[item.prop]}}%</span>
                      <div :style="{width: scope.row[item.prop] + '%' }"></div>
                    </div>
                  </div>
                  <!--幅度变化-->
                  <template v-if="item.type == 'range'">
                    <span :class="scope.row[item.prop][0] == '↑' ? 'red' : 'green' ">{{scope.row[item.prop][0]}}</span>
                    <span>{{scope.row[item.prop].substring(1,7)}}</span>
                  </template>
                  <!--箭头-->
                  <template v-if="item.type == 'jiantou'">
                    <span v-if="scope.row[item.prop] == '↑'" class="green">↑</span>
                    <span v-else-if="scope.row[item.prop] == '↓'" class="red">↓</span>
                    <span v-else>--</span>
                  </template>
                  <!--水质类别-->
                  <template v-if="item.type == 'gename'">
                    <span v-html="scope.row[item.prop]"></span>
                  </template>
                  <!-- 报警级别 -->
                  <div v-if="item.type == 'alarmLevel'" class="colorBlock">
                    <span :style="'background:'+scope.row[item.prop][1]" style="color:#fff">{{scope.row[item.prop][0]}}</span>
                  </div>
                  <template v-if="item.type == 'clickNum'">
                    <span v-if="scope.row[item.prop] > 0" @click="clickNum(scope.row,item.prop)" :class="item.classColor">{{scope.row[item.prop]}}</span>
                    <span v-else>{{scope.row[item.prop]}}</span>
                  </template>

                  <template v-if="item.type == 'g-color'">
                    <span :style="{ padding: '10px 20px', borderRadius: '3px', background: scope.row[item.prop] }"></span>
                  </template>

                  <template v-if="item.type == 'icon_click'">
                    <span
                      v-for="item2 in tableData.btnData.filter(({ name }) => scope.row[item.prop].includes(name))"
                      class="operation_btn iconfont"
                      :id="item2.name"
                      :title="item2.label"
                      :class="item2.icon"
                      @click="operationClick(item2, scope,item)"
                    >
                     <i v-if="item2.txt">{{ item2.txt }}</i>
                      <!-- <i v-if="item2.icon && item2.icon == '审'">审</i> -->
                    </span>
                  </template>

                  <template v-if="item.type == 'icon_hover'">
                    <span
                      v-for="item2 in tableData.btnData.filter(({ name }) => scope.row[item.prop].includes(name))"
                      class="operation_btn iconfont 111"
                      :id="item2.name"
                      :title="item2.label"
                      :class="item2.icon"
                      @mouseover="mouseOver(item2, scope,item)"
                      @mouseleave="mouseLeave(item2, scope,item)"
                    />
                  </template>

                  <!--污染物类型-->
                  <template v-if="item.type == 'contaminated'">
                    <template v-if="typeof(scope.row[item.prop]) == 'string' || typeof(scope.row[item.prop]) == 'number'">
                      <span>{{scope.row[item.prop]}}</span>
                    </template>
                    <template v-else>
                    <span
                      v-if="scope.row[item.prop].value == null ? scope.row[item.prop].value = '-' : scope.row[item.prop].value">{{scope.row[item.prop].value}}</span>
                    </template>
                  </template>
                  <!--开关-->
                  <template v-if="item.type == 'switch'">
                    <el-switch v-model="scope.row[item.prop]" @change="switchChange(scope.row)"></el-switch>
                  </template>
                  <template v-if="item.type == 'radiosDanxuan'">
                    <!-- <el-switch v-model="scope.row[item.prop]" @change="switchChange(scope.row)"></el-switch> -->
                    <el-radio-group v-model="scope.row[item.prop]">
                      <el-radio :label="li.label" v-for="li in item.radioList">{{li.name}}</el-radio>
                    </el-radio-group>
                  </template>
                  <div v-if="item.type == 'tableCellEdit'" class="oneInput">
                    <editable-cell
                      v-if="!scope.row.hidden"
                      @inputChange="inputChange"
                      :show-input="item.editMode"
                      v-model="scope.row[item.prop]"
                      v-bind="item.elePorp"
                    >
                      <!-- style="padding:0 10px"  空字符点击可以编辑-->
                      <span slot="content" style="padding:0 1px">{{ scope.row[item.prop] || scope.row[item.prop] == 0 ? scope.row[item.prop] : "--" }}</span>
                    </editable-cell>
                  </div>
                  <template v-if="item.type == 'selection'">
                    <el-checkbox
                      v-model="checkList"
                      :label="scope.row[item.prop]"
                      @change="handleCheckChange(scope.row)"
                      :disabled="scope.row.disabled"
                      :checked="scope.row.checked"
                      class="selection"
                    ></el-checkbox> 
                  </template>
                  <template v-if="item.type == 'slot'">
                    <slot :name="item.name" :props="scope.row"></slot>
                  </template>
                </template>
                <template v-else>
                  <!-- <span>{{scope.row[item.prop] || "--" }}</span> -->
                  <span>{{scope.row[item.prop]===""?"--":scope.row[item.prop]}}</span>
                </template>
              </template>
            </el-table-column>
          </template>
          <!--操作-->
          <el-table-column
            v-if="tableData && tableData.titleData && tableData.titleData.length>0 && tableData.btnData && tableData.btnData.length>0 && !property.hideOperation"
            label="操作" align="center" fixed="right" :width="attribute.operationWidth">
            <template slot-scope="scope">
              <template v-if="scope.row.disablebutton">
                <template v-for="item in tableData.btnData">
                  <template v-if="scope.row.disablebutton.indexOf(item.name)!==-1">
            <!-- <span class="operation_btn_no iconfont" :class="item.icon"></span> -->
                  </template>
                  <template v-else>
                    <span class="operation_btn iconfont" :id="item.name" :title="item.label" :class="item.icon"
                          @click="operationClick(item,scope)"></span>
                  </template>
                </template>
              </template>
              <template v-else>
                <template v-for="item in tableData.btnData">
                  <span class="operation_btn iconfont" :id="item.name" :title="item.label" :class="item.icon"
                        @click="operationClick(item,scope)"></span>
                </template>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-table-bar>
      <!--设置显示隐藏-->
      <div id="shBox" v-if="property.isSet">
        <el-popover placement="bottom" trigger="click" width="400">
          <div>
            <template v-for="item in tableData.titleData">
              <div style="display: inline-block;width: 33.33%;">
                <el-checkbox v-model="item.showhide">{{item.label}}</el-checkbox>
              </div>
            </template>
          </div>
          <span slot="reference" class="iconfont iconshaixuan1" title="筛选表头"></span>
        </el-popover>
      </div>
    </div>
    <!--分页-->
    <div id="query" v-if="pageData && pageData.currentPage">
      <el-pagination background :current-page="pageJson.currentPage" :page-sizes="pageJson.pageSizes"
                     :page-size="pageJson.pageSize" :layout="pageJson.layout" :total="pageJson.total"
                     @size-change="handleSizeChange" @current-change="handleCurrentChange">
      </el-pagination>
    </div>
  </div>
</template>
<!--
tableData:{
  listData:[表格数据]
  titleData:[表头数据]
  btnData:[操作按钮数据]
}
loading:false,加载动画
非必传（列表属性）
property:{
  typeName:"当前页面的name"（必传）
  emptyText:空数据时显示的文本内容-String(必传)
  stripe:是否为斑马纹-boolean(false)
  border:是否带有纵向边框	-boolean(true)
  size:Table的尺寸-string-medium/small/mini(medium)
  fit:列的宽度是否自撑开-boolean(true)
  showHeader:是否显示表头-boolean	(true)
  highlightCurrentRow:是否要高亮当前行-boolean(true)
  reserveSelection	仅对 type=selection 的列有效，类型为 Boolean，为 true 则会在数据更新之后保留之前选中的数据（需指定 row-key）	Boolean	—	true
  operationWidth:"操作列的宽度"
  rowStyle:"行的 style 的回调方法，也可以使用一个固定的 Object 为所有行设置一样的 Style。"
  cellStyle:"单元格的 style 的回调方法，也可以使用一个固定的 Object 为所有单元格设置一样的 Style。"
  rowClassName:"行的 className 的回调方法，也可以使用字符串为所有行设置一个固定的 className。"
  cellClassName:"单元格的 className 的回调方法，也可以使用字符串为所有单元格设置一个固定的 className。"
  spanMethod:"单元格合并的 className 的回调方法，也可以使用字符串为所有单元格设置一个固定的 className。"
  isCheckBox:"是否显示多选" false
  isOrder:"是否显示序号" true
  isSet:"是否显示设置按钮" false
}
跟随表头数据传递过来的属性
{
  label	显示的标题	string	—	—
  prop	对应列内容的字段名，也可以使用 property 属性	string	—	—
  width	对应列的宽度	string	—	—
  minwidth	对应列的最小宽度，与 width 的区别是 width 是固定的，min-width 会把剩余宽度按比例分配给设置了 min-width 的列	string	—	—
  fixed	列是否固定在左侧或者右侧，true 表示固定在左侧	string, boolean	true, left, right	—
  sortable	对应列是否可以排序，如果设置为 'custom'，则代表用户希望远程排序，需要监听 Table 的 sort-change 事件	boolean, string	true, false, 'custom'	false
  resizable	对应列是否可以通过拖动改变宽度（需要在 el-table 上设置 border 属性为真）	boolean	—	true
  showoverflowtooltip	当内容过长被隐藏时显示... tooltip	Boolean	—	false
  align	对齐方式	String	left/center/right	left
  headeralign	表头对齐方式，若不设置该项，则使用表格的对齐方式	String	left/center/right	—
  showhide:是否显示对应列
}
分页
pageData:{
   currentPage=1  当前页数
   pageSizes="[20, 30, 40, 50]" 每页显示个数选择器的选项设置
   pageSize="20" 当前每页显示的条数
   layout="total, sizes, prev, pager, next, jumper" 组件布局，子组件名用逗号分隔
   total="0" 总条数
}

表格的类名(同一个页面有多个表格需要传此参数)
tableClass 给同一个页面的不同表格添加类名，通过类名来改变每一个表格滚动条的样式

传递的事件
@cellClick(params)-单元格点击事件
@rowClick(params)-表格每一行点击事件
@headerClick(params)-表格每一列的表头点击事件
@sortChange(params)-排序发生变化时事件
@selectionChange(params)-当选项发生变化时事件
@operationClick(item, scope)-列表操作按钮点击事件
@mouseOver(item, scope)-列表操作按钮划入事件
@mouseLeave(item, scope)-列表操作按钮划出事件
@handleSizeChange(val)-每页多少条
@handleCurrentChange(val)-当前页

-->
<script>
  import configBasics from '../../utils/configBasics'
  import EditableCell from "./EditeableCell"
  export default {
    name: "SuperTable",
    props: ["tableData", "property", "pageData", "loading", "tableClass", "radioIdFromParent", "checkFromParent"],
    components: {
      EditableCell
    },
    data() {
      return {
        hasTableborder: false,//判断表格每个单元格是否有左右边框（默认没有）
        clickIndex: "",//点击索引
        /*属性*/
        attribute: {
          stripe: false,
          border: true,
          size: "medium",
          fit: true,
          showHeader: true,
          highlightCurrentRow: true,
          reserveSelection: true,
          operationWidth: "150px",
          isOrder: true,
          lazy:false,
          showsummary:false,
          treeprops:{children: 'children', hasChildren: 'hasChildren'}
        },
        radioId: '',
        /*表格高度*/
        tabHeight: "627px",
        /*分页*/
        pageJson: {
          currentPage: 1,
          pageSizes: [20, 30, 40, 50],
          pageSize: 20,
          total: 0,
          layout: "total, sizes, prev, pager, next, jumper"
        },
        currentRow:null,
        checkList: []
      }
    },
    created() {
      let that = this;
      /*初始化数据*/
      this.radioId = this.radioIdFromParent;
      this.checkList = this.checkFromParent;
      that.initializeDataFun();
    },
    watch: {
      radioIdFromParent: {
        handler() {
          this.radioId = this.radioIdFromParent
        }
      },
      checkFromParent: {
        handler() {
          this.checkList = this.checkFromParent
        },
        deep:true
      },
      pageData: {
        handler() {
          let that = this;
          /*获取table的高度*/
          that.tableCssFun();
          /*初始化数据*/
          that.initializeDataFun();
        },
        deep: true
      },
      tableData: {
        handler() {
          let that = this;
          /*获取table的高度*/
          that.tableCssFun();
          /*初始化数据*/
          that.initializeDataFun();
           this.$nextTick(()=>{
            setTimeout(()=>{
              const fixedBody =  document.querySelector(".el-table__fixed-body-wrapper");
              const isScroll =  this.ruleValidate(this.tableData.titleData,'fixed');
              if(fixedBody&&isScroll){
                fixedBody.style.height = this.tableData.listData.length * 45 + "px";
              }
            },200)
            
          })
        },
        deep: true
      },
      "property.tabHeight":{
        handler() {
          if(this.property.tabHeight){
            this.tabHeight = this.property.tabHeight
          }
        },
        deep: true
      }
    },
    methods: {
      /*初始化数据*/
      initializeDataFun() {
        let that = this;
        // 初始化属性
        if (that.property) {
          if (that.property.stripe !== undefined) {
            that.attribute.stripe = that.property.stripe;
          }
          // if (that.property.border !== undefined) {
          //   that.attribute.border = that.property.border;
          // }
          if (that.property.border === undefined || that.property.border === false) {
            that.hasTableborder = false
          } else {
            that.hasTableborder = true
          }
          if (that.property.size !== undefined) {
            that.attribute.size = that.property.size;
          }
          if (that.property.fit !== undefined) {
            that.attribute.fit = that.property.fit;
          }
          if (that.property.showHeader !== undefined) {
            that.attribute.showHeader = that.property.showHeader;
          }
          if (that.property.highlightCurrentRow !== undefined) {
            that.attribute.highlightCurrentRow = that.property.highlightCurrentRow;
          }
          if (that.property.reserveSelection !== undefined) {
            that.attribute.reserveSelection = that.property.reserveSelection;
          }
          if (that.property.operationWidth !== undefined) {
            that.attribute.operationWidth = that.property.operationWidth;
          }
          if (that.property.isOrder !== undefined) {
            that.attribute.isOrder = that.property.isOrder;
          }
          
          if(that.property.fixheight){
            // that.tabHeight = false;
            that.tabHeight = "auto";
          }
          if(that.property.tabHeight){
            that.tabHeight = that.property.tabHeight;
          }
        }
        // 初始化分页
        if (that.pageData) {
          if (that.pageData.currentPage !== undefined) {
            that.pageJson.currentPage = that.pageData.currentPage;
          }
          if (that.pageData.pageSizes !== undefined) {
            that.pageJson.pageSizes = that.pageData.pageSizes;
          }
          if (that.pageData.pageSize !== undefined) {
            that.pageJson.pageSize = that.pageData.pageSize;
          }
          if (that.pageData.total !== undefined) {
            that.pageJson.total = that.pageData.total;
          }
        }
      },
      textClickFun(row,type){
        this.$emit('textClickFun',{row,type});
      },
      // 懒加载
      load( tree, treeNode, resolve ){
        this.$emit('load', tree, treeNode, resolve );
      },  
      /*单元格点击事件*/
      cellClick(row, column, event) {
        this.$emit('cellClick', row, column, event);
      },
      /*表格每一行点击事件*/
      rowClick(row, column, event) {
        this.$emit('rowClick', row, column, event);
      },
      /*表格每一列的表头点击事件*/
      headerClick(row, column, event) {
        this.$emit('headerClick', row, column, event);
      },
      /*获取table的高度*/
      tableCssFun() {
        let that = this;
        that.$nextTick(function () {
          /*是否有分页*/
          if (that.pageData && that.pageData.currentPage) {
            if (that.tableClass) {
              $("." + that.tableClass).css({
                "padding-bottom": "52px"
              });
            } else {
              $("#SuperTable #table").css({
                "padding-bottom": "52px"
              });
            }
          } else {
            if (that.tableClass) {
              $("." + that.tableClass).css({
                "padding-bottom": "0"
              });
            } else {
              $("#SuperTable #table").css({
                "padding-bottom": "0"
              });
            }
          }
          setTimeout(function () {
            let h;
            // let tableHeight = $("#SuperTable #table .el-table__fixed-header-wrapper").height();
            let tableHeight = $("#SuperTable #table .el-table__header-wrapper").height();
            // console.log("表头的高度====" + tableHeight);
            if (that.tableClass) {
              h = $("." + that.tableClass).height();
            } else {
              //  获取当前的层级
              let curLevel = that.getMaxFloor(that.tableData.titleData)
              if(that.property.fixheight){//设置自适应高度则按数据个数计算table高度
                // let doublecol = that.tableData.titleData[0].children?47:0;//根据children判断是否是多表头
                // console.log(doublecol)
                //根据children判断是否是多表头
                // let isHorizontal = that.tableData.titleData[0].isHorizontal ? true : false;
                let isHorizontal = false;
                if(that.tableData.titleData && that.tableData.titleData.length>0){
                  isHorizontal = that.tableData.titleData[0].isHorizontal ? true : false;
                }
                that.tableData.titleData.map((item, index) => {
                  let doublecol = that.tableData.titleData[index].children
                    ? 47
                    : 0;
                });
                // console.log(isHorizontal)
                // console.log(curLevel)
                // console.log(that.property.hideOperation)
                // console.log(that.tableData.btnData)
                let operationVal = false;
                that.tableData.titleData.map((e,i) => {
                  if(e.type == "icon_click"){
                    operationVal = true;
                  }
                })
                if(curLevel == 1){
                  // if(that.property.hideOperation || (that.tableData.btnData && that.tableData.btnData.length>0)){
                  //   h = that.tableData.listData.length * 45 + 49*curLevel;
                  // }else{
                  //   h = that.tableData.listData.length * 44 + 49*curLevel; //高度为数据个数*每行数据个数加上表头高度
                  // }
                  if(that.property.hideOperation){
                    // console.log('隐藏基本操作')
                    h = that.tableData.listData.length * 44 + 49*curLevel; //高度为数据个数*每行数据个数加上表头高度
                    // h = that.tableData.listData.length * 44 + tableHeight + 2; //TODO
                    if(operationVal){
                      h = that.tableData.listData.length * 45 + 49*curLevel; 
                      // console.log('设置单独的操作')
                    }
                  }else{
                    if(that.tableData.btnData && that.tableData.btnData.length>0){
                      h = that.tableData.listData.length * 45 + 49*curLevel; //高度为数据个数*每行数据个数加上表头高度
                    }else{
                      
                    }
                  }
                }else{ 
                  // 多表头会出现纵向滚动条，用44.3
                  // h = that.tableData.listData.length * 44.3 + 49*curLevel;
                  if(isHorizontal){ // 多表格，有横向滚动
                    // console.log('多表格，有横向滚动')
                    h = that.tableData.listData.length * 45.3 + 48*curLevel;
                  
                  }else{ // 多表格，无横向滚动
                    // console.log('多表格，无横向滚动')
                    // h = that.tableData.listData.length * 44.3 + 49*curLevel;
                    // 表格数据量乘以表格高度 + 表头高度乘以表头级别 + table上下border
                    // 有btnData按钮的高度为45
                    if(that.tableData.btnData && that.tableData.btnData.length>0){
                        h = that.tableData.listData.length * 45 + 47*curLevel + 2;
                    }else{
                        h = that.tableData.listData.length * 44 + 47*curLevel + 2;
                        // h = that.tableData.listData.length * 45 + 47*curLevel + 2;
                    }
                  }
                }
               
                if(that.tableData.listData.length==0){
                  // h += 49
                  h = 44*10 + h;
                }
              }else{
                h = $("#table").height();
              }
            }
            that.tabHeight = h+ "px";
            /*滚动条样式*/
            that.scrollTableCssFun();
          }, 100);
        });
      },
      // 获取最大层级
      getMaxFloor(treeData) {
        let floor = 0;
        let v = this;
        let max = 0;
        function each(data, floor) {
          data.forEach(e => {
            e.floor = floor;
            if (floor > max) {
              max = floor;
            }
            if (e.children && e.children.length > 0) {
              each(e.children, floor + 1);
            }
          });
        }
        each(treeData, 1);
        return max;
      },
      /*滚动条样式*/
      scrollTableCssFun() {
        if(this.property.fixheight){//设置自动高度则列表不加滚动条样式
          return
        }
        let that = this;
        setTimeout(function () {
          if (that.tableClass) {
            configBasics.niceScrollFun($("." + that.tableClass + " .el-table>div").eq(2), true);
            $("." + that.tableClass + " .el-table>div").eq(2).getNiceScroll().resize();
          } else {
            configBasics.niceScrollFun($("#table .el-table>div").eq(2), true);
            $("#table .el-table>div").eq(2).getNiceScroll().resize();
          }
          if (that.tableClass) {
            let leftH = $("." + that.tableClass + " .el-table__fixed").height();
            let leftHead = $("." + that.tableClass + " .el-table__fixed .el-table__fixed-header-wrapper").outerHeight();
            $("." + that.tableClass + " .el-table__fixed .el-table__fixed-body-wrapper").outerHeight(leftH - leftHead);
            let rightH = $("." + that.tableClass + " .el-table__fixed-right").height();
            let rightHead = $("." + that.tableClass + " .el-table__fixed-right .el-table__fixed-header-wrapper").outerHeight();
            $("." + that.tableClass + " .el-table__fixed-right .el-table__fixed-body-wrapper").outerHeight(rightH - rightHead);
            $("." + that.tableClass + " .el-table>div").eq(2).getNiceScroll().resize();
          } else {
            let leftH = $("#SuperTable .el-table__fixed").height();
            let leftHead = $("#SuperTable .el-table__fixed .el-table__fixed-header-wrapper").outerHeight();
            $("#SuperTable .el-table__fixed .el-table__fixed-body-wrapper").outerHeight(leftH - leftHead);
            let rightH = $("#SuperTable .el-table__fixed-right").height();
            let rightHead = $("#SuperTable .el-table__fixed-right .el-table__fixed-header-wrapper").outerHeight();
            $("#SuperTable .el-table__fixed-right .el-table__fixed-body-wrapper").outerHeight(rightH - rightHead);
            $("#table .el-table>div").eq(2).getNiceScroll().resize();
          }
        }, 300);
      },
      /*表头样式*/
      headerCellStyle() {
        return {
          background: "#f7f7f7",
          color: "#000000"
        }
      },
      radioChange(row) {
        this.$emit('radioChange', row, (id) => this.radioId = id)
      },
      handleCheckChange(row){
        this.$emit('checkChange', row, this.checkList)
      },
      /*行key*/
      rowKey(row) {
        return row.id;
      },
      /*序号*/
      indexMethod(val) {
        let that = this;
        let current = that.pageJson.currentPage - 1;
        let pageSize = that.pageJson.pageSize;
        let index = (current * pageSize) + val + 1;
        return index;
      },
      /*拖动列宽事件*/
      headerDragend() {
        let that = this;
        /*滚动条样式*/
        that.scrollTableCssFun();
      },
      // 按钮事件
      switchChange(row){
        this.$emit('switchChange',row);
      },
      // 点击数字
      clickNum(row,type){
        this.$emit('clickNum',{row,type});
      },
      /*排序发生变化时事件*/
      sortChange(params) {
        // console.log(params, "排序发生变化时事件");
        this.$emit("sortChange", params);
      },
      /*当选项发生变化时事件*/
      selectionChange(params) {
        // console.log(params, "当选项发生变化时事件");
        this.$emit("selectionChange", params);
      },
      /*用户手动勾选Checkbox*/
      selectChange(selection, row) {
        // console.log(selection, row, "用户手动勾选数据行的 Checkbox 时触发的事件");
        this.$emit("selectChange", selection, row);
      },
      // 当用户手动勾选全选 Checkbox 时触发的事件
      selectAllChange(selection){
        this.$emit("selectAllChange", selection);
      },
      /*列表操作按钮点击事件*/
      operationClick(item, scope,list) {
        // console.log(item, scope, "列表操作按钮点击事件");
        this.$emit("operationClick", item, scope,list);
      },
      /*列表操作按钮划入事件*/
      mouseOver(item, scope) {
        // console.log(item, scope, "列表操作按钮划入事件");
        this.$emit("mouseOver", item, scope);
      },
      /*列表操作按钮划出事件*/
      mouseLeave(item, scope) {
        // console.log(item, scope, "列表操作按钮划出事件");
        this.$emit("mouseLeave", item, scope);
      },
      /*分页的点击事件*/
      handleSizeChange(val) {
        // console.log(`每页 ${val} 条`);
        this.$emit("handleSizeChange", val);
        if (this.tableClass) {
          $("." + this.tableClass + " .el-table>div").eq(2).scrollTop(0);
        } else {
          $("#table .el-table>div").eq(2).scrollTop(0);
        }
      },
      handleCurrentChange(val) {
        // console.log(`当前页: ${val}`);
        this.$emit("handleCurrentChange", val);
        if (this.tableClass) {
          $("." + this.tableClass + " .el-table>div").eq(2).scrollTop(0);
        } else {
          $("#table .el-table>div").eq(2).scrollTop(0);
        }
      },
      handleCurrentRowChange(val){
        this.currentRow = val;
        this.$emit("handleCurrentRowChange", val);
      },
      doLayout() {
        this.$refs.mytable.doLayout()
      },
      // 清除多选框选中状态
      clearSelection(){
        this.$refs.mytable.clearSelection();
      },
      toggleRowSelection(params){
        this.$refs.mytable.toggleRowSelection(params);
      },
      // 递归判断数组中值类型为对象的属性是否包含某个值
      ruleValidate(data, value) {
        let flag = false;
        function judgeChildren(data) {
          data.forEach(e => {
            if (flag) {
              return;
            }
            if (e[value]) {
              flag = true;
              return;
            } else if (e.children && e.children.length) {
              judgeChildren(e.children);
            }
          });
        }
        judgeChildren(data);
        return flag;
      },
      // 表头自定义点击事件
      customHeaderClick(item){
        this.$emit("customHeaderClick", item);
      },
      inputChange(val){
        this.$emit("inputChange", val);
      }
    },
    mounted() {
      let that = this;
      if(that.property.fixheight){
        that.scrollTableCssFun();
        /*获取table的高度*/
        that.tableCssFun();
      }else{
        /*获取table的高度*/
        that.tableCssFun();
        /*监听折叠事件*/
        that.BUS.$on('menuZDCFun', function (params) {
          /*获取table的高度*/
          that.tableCssFun();
        });
        /*窗口改变事件*/
        $(window).resize(function () {
          /*获取table的高度*/
          that.tableCssFun();
        });
      }
    }
  }
</script>

<style scoped>
  
  #SuperTable {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 0;
  }
  .el-table td.el-table__cell span{
    white-space:pre;
  }


  /*表格*/
  #table {
    width: 100%;
    height: 100%;
    overflow: hidden;
    position: relative;
    padding-bottom: 0;
  }

  /*操作按钮*/
  #SuperTable .operation_btn {
    display: inline-block;
    margin: 0 6px;
    font-size: 14px;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 50%;
    cursor: pointer;
  }

  #SuperTable .operation_btn_no {
    display: inline-block;
    width: 24px;
    height: 24px;
    text-align: center;
    line-height: 24px;
    border-radius: 50%;
    margin: 0 6px;
    font-size: 14px;
    color: #cccccc;
    cursor: not-allowed;
    background: #eeeeee;
  }

  /*编辑*/
  #SuperTable #editButton {
    color: #079fef;
    background-color: #ddeefc;
  }

  #SuperTable #editButton:hover {
    color: #0282ee;
    background-color: #a7d5fb;
  }
  /*选择*/
  #SuperTable #choiceButton {
    color: #079fef;
    background-color: #ddeefc;
  }

  #SuperTable #choiceButton:hover {
    color: #0282ee;
    background-color: #a7d5fb;
  }
  #yunxiazai{
    color: #4e86f8;
    border-radius: 0!important;
    font-size: 26px!important;
  }
  #shenheButton {
    color: #fc5d6f;
    background-color: #fee3e7;
  }
  #beianButton {
    color: #079fef;
    background-color: #ddeefc;
  }
  #beianButton:hover {
    color: #079fef;
    background-color: #ddeefc;
  }
  #chouchaButton {
    color: #079fef;
    background-color: #ddeefc;
  }
  #chouchaButton:hover {
    color: #079fef;
    background-color: #ddeefc;
  }
  #chouchaIng, #chouchaEd{
    width: auto!important;;
  }

  /*删除*/
  #deleteButton, #jinyongBtn{
    color: #fc5d6f;
    background-color: #fee3e7;
  }

  #deleteButton:hover, #jinyongBtn:hover {
    color: #fe485c;
    background-color: #fdb7c0;
  }

  /*详情*/
  #detailsButton {
    color: #fd8320;
    background-color: #ffe7d5;
  }

  /*详情*/
  #detailsButton:hover {
    color: #ff6c00;
    background-color: #fecda7;
  }

  /*下载*/
  #xiazaiButton,#jiluButton, #qiyongBtn, #greenButton{
    color: #3eaf0e;
    background-color: #d8efcf;
  }
  /*下载*/
  #xiazaiButton:hover,#jiluButton:hover, #qiyongBtn:hover, #greenButton:hover {
    color: #329c06;
    background-color: #bfe5b0;
  }

  /*整改*/
  #rectifyButton {
    color: #8f82bc;
    background-color: #e5ddff;
  }

  /*整改*/
  #rectifyButton:hover {
    color: #8f82bc;
    background-color: #e5ddff;
  }

   /*监测数据*/
  #monitorButton {
    color: #3eaf0e;
    background-color: #d8efcf;
  }

  /*监测数据*/
  #monitorButton:hover {
    color: #329c06;
    background-color: #bfe5b0;
  }

   /*污染源*/
  #pollutionSourceButton {
    color: #fc5d6f;
    background-color: #fee3e7;
  }

  /*污染源*/
  #pollutionSourceButton:hover {
    color: #fc5d6f;
    background-color: #fee3e7;
  }

  /*相机*/
  #cameraButton {
    color: #079fef;
    background-color: #ddeefc;
  }

  /*相机*/
  #cameraButton:hover {
    color: #079fef;
    background-color: #ddeefc;
  }

  /*地图定位 */
  #maplocationButton{
    color: #358bef;
    background-color: #e9f3fc;
  }
  #maplocationButton:hover{
    color: #3b8ff0;
    background-color: #a7d5fb;
  }
  /*附件 */
  #enclosureButton{
    color: #43b115;
    background-color: #d8efcf;
  }
  #enclosureButton:hover{
    color: #3aa00f;
    background-color: #bfe5b0;
  }
  /*人员 */
  #planButton{
    color: #079fef;
    background-color: #ddeefc;
  }
  #planButton:hover {
    color: #0282ee;
    background-color: #a7d5fb;
  }
  /*历史预案 */
  #historyButton{
    color: #8457ed;
    background-color: #e9e2f9;
  }
  #historyButton:hover {
    color: #8b60f0;
    background-color: #d9c9ff;
  }
  /*企业档案 */
  #enterpriseButton{
    color: #fd8320;
    background-color: #ffe7d5;
  }
  #enterpriseButton:hover{
    color: #ff6c00;
      background-color: #fecda7;
  }
  #linkButton{
     color: #fd8320;
    background-color: #ffe7d5;
  }
  #linkButton:hover{
    color: #ff6c00;
      background-color: #fecda7;
  }
  #conformance{
     color: #fc5d6f;
    background-color: #fee3e7;
    font-size: 16px !important;
  }
  #conformance:hover{
     color: #fc5d6f;
    background-color: #fee3e7;
    font-size: 16px !important;
  }
  /*分页*/
  #query {
    width: 100%;
    padding: 10px 0;
    text-align: right;
    position: absolute;
    bottom: 0;
    left: 0;
    z-index: 10;
    background-color: #fff;
  }

  /*设置显示隐藏*/
  #shBox {
    position: absolute;
    top: 14px;
    right: 12px;
    z-index: 10;
  }

  #shBox .iconfont {
    color: #909192;
    font-size: 16px;
    cursor: pointer;
  }

  #shBox .iconfont:hover {
    color: #0e90fe;
  }
  #SuperTable.noTableborder .el-table .el-radio__inner, #SuperTable.noTableborder .el-table .el-checkbox__inner{
    border-color: #dddddd !important;
  }

  .greenBlock{
    /* position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; */
    background: #40cc23;
    /* line-height: 44px; */
    color: #fff;
  }

  .orangeBlock{
    /* position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0; */
    background: #f56529;
    /* line-height: 44px; */
    color: #fff;
  }
  .colorBlock{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    line-height: 44px;
  }
  .colorBlock span{
    display: inherit;
    height: 43px;
    height: 100%;
  }
  .blockDiv{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    line-height: 44px;
  }
  .blockDiv .bgDivs{
    width: 100%;
    height: 100%;
  }
  .blockDiv .bgDivs span{
    z-index: 10;
    position: absolute;
    left: 40%;
  }
  .blockDiv .bgDivs div{
    height: 100%;
    background: #40cc23;
    position: absolute;
    top: 0;
    left: 0;
  }
</style>
<style>
  /* #SuperTable .oneInput .edit-cell .el-input__inner{
    position: absolute;
    top: 0;
    left: 0;
  } */
  #SuperTable .oneInput .edit-cell .el-input--small .el-input__inner{
    height: 20px;
    line-height: 20px;
    padding: 0;
  }
  /*无左右边框表格*/
  #SuperTable.noTableborder .el-table {
    border-left-color: #dddddd !important;
    border-right-color: transparent !important;
    border-top-color: #dddddd !important;
  }

  #SuperTable.noTableborder .el-table * {
    border-left-color: transparent !important;
    border-right-color: transparent !important;
    border-bottom-color: #dddddd !important;
    border-top-color: #dddddd !important;
  }

  #SuperTable.noTableborder .el-table .el-table__fixed-right {
    border-right: 1px solid #dddddd !important;
  }

  #SuperTable.hasTableborder .el-table, #SuperTable.hasTableborder .el-table * {
    border-right-color: #dddddd !important;
    border-bottom-color: #dddddd !important;
  }

  /*有左右边框表格*/
  #SuperTable.hasTableborder .el-table {
    border-top: 1px solid #dddddd !important;
    border-bottom: 1px solid #dddddd !important;
    border-left: 1px solid #dddddd !important;
  }

  #SuperTable.hasTableborder .el-table th {
    border-left-width: 0 !important;
  }

  #SuperTable .el-table--border::after, #SuperTable .el-table--group::after, #SuperTable .el-table::before,
  #SuperTable .el-table__fixed-right::before, #SuperTable .el-table__fixed::before {
    background-color: #dddddd !important;
  }

  #SuperTable .el-table .sort-caret.descending {
    border-color: transparent !important;
    border-top-color: #c0c4cc !important;
  }

  #SuperTable .el-table .descending .sort-caret.descending {
    border-color: transparent !important;
    /* border-top-color: #409eff !important; */
  }

  #SuperTable .el-table .sort-caret.ascending {
    border-color: transparent !important;
    border-bottom-color: #c0c4cc !important;
  }

  #SuperTable .el-table .ascending .sort-caret.ascending {
    border-color: transparent !important;
    /* border-bottom-color: #409eff !important; */
  }

  #SuperTable .el-table__fixed, #SuperTable .el-table__fixed-right {
    height: 100% !important;
  }

  #SuperTable .el-table__fixed-right {
    right: 0 !important;
  }

  #SuperTable .el-table__body .hover-row > td {
    background-color: #fafafa !important;
  }

  #SuperTable .el-table__body .current-row td {
    background-color: #fafafa !important;
  }

  #SuperTable .el-table--medium th {
    padding: 0 !important;
    height: 47px !important;
  }

  .OffLine {
    display: inline-block;
    width: 10px;
    height: 11px;
    border: 1px solid #ccc;
    background: #ccc;
    border-radius: 50%;
  }

  .regular {
    display: inline-block;
    width: 10px;
    height: 11px;
    border: 1px solid rgb(33, 213, 71);
    background: rgb(33, 213, 71);
    border-radius: 50%;
  }

  .abnormal {
    display: inline-block;
    width: 10px;
    height: 11px;
    border: 1px solid red;
    background: red;
    border-radius: 50%;
  }

  .exceedingStandard {
    display: inline-block;
    width: 10px;
    height: 11px;
    border: 1px solid orange;
    background: orange;
    border-radius: 50%;
  }

  .red {
    color: red;
    font-size: 18px;
    font-weight: initial;
  }

  .green {
    color: rgb(33, 213, 71);
    font-size: 18px;
    font-weight: initial;
  }

  .lvse {
    color: rgb(33, 213, 71);
    cursor: pointer;
  }
  .hongse {
    color: red;
    cursor: pointer;
  }
  .huangse{
    color: orange;
    cursor: pointer;
  }
  
  .hands{
    cursor: pointer;
    color: #0e90fe;
  }

  #SuperTable.hasTableborder .el-table .hands, #SuperTable.hasTableborder .el-table * .hands{
    border-bottom: 1px solid #0e90fe!important;
  }

  /* 解决缓存页面返回时的操作按钮样式错乱 */
  .el-table__fixed-right .el-table__fixed-body-wrapper {
    height: 100% !important;
  }
  .scrollbar .el-table__body-wrapper::-webkit-scrollbar{
    width:7px;
    height:7px;
  }
  .scrollbar .el-table__body-wrapper::-webkit-scrollbar-track{
    background: rgb(239, 239, 239);
    border-radius:2px;
  }
   .scrollbar .el-table__body-wrapper::-webkit-scrollbar-thumb{
    background: #ccc;
    opacity: 0;
    border-radius:10px;
  }
  .scrollbar .el-table__body-wrapper::-webkit-scrollbar-thumb:hover{
    background: #bfbfbf;
  }
  .selection .el-checkbox__label{
    display: none;
  }
</style>
