<template>
  <div @click="onFieldClick" id="edit-cell">
    <!-- <el-tooltip
      v-if="!editMode && !showInput"
      :placement="toolTipPlacement"
      :open-delay="toolTipDelay"
      :content="toolTipContent"
    >
      <div tabindex="0" @keyup.enter="onFieldClick" >
        <slot name="content"></slot>
      </div>
    </el-tooltip> -->
    <div
      tabindex="0"
      style="cursor: text"
      @keyup.enter="onFieldClick"
      v-if="!editMode && !showInput"
    >
      <slot name="content"></slot>
    </div>
    <component
      :is="editableComponent"
      v-if="editMode || showInput"
      ref="input"
      size="medium"
      @focus="onFieldClick"
      @keyup.enter.native="onInputExit"
      @input="onInputChange"
      v-on="listeners"
      v-bind="$attrs"
      v-model="model"
      class="edit-input"
    >
      <slot name="edit-component-slot"></slot>
    </component>
  </div>
</template>
<script>
//  editMode: false,
// type: "tableCellEdit"
export default {
  name: "editable-cell",
  inheritAttrs: false,
  props: {
    value: {
      type: String | Number,
      default: ""
    },
    // toolTipContent: {
    //   type: String,
    //   default: "点击编辑"
    // },
    // toolTipDelay: {
    //   type: Number,
    //   default: 500
    // },
    // toolTipPlacement: {
    //   type: String,
    //   default: "top-start"
    // },
    showInput: {
      type: Boolean,
      default: false
    },
    editableComponent: {
      type: String,
      default: "el-input"
    },
    closeEvent: {
      type: String,
      default: "blur"
    }
  },
  data() {
    return {
      editMode: false
    };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      }
    },
    listeners() {
      return {
        [this.closeEvent]: this.onInputExit,
        ...this.$listeners
      };
    }
  },
  methods: {
    onFieldClick() {
      this.editMode = true;
      this.$nextTick(() => {
        let inputRef = this.$refs.input;
        if (inputRef) {
          inputRef.focus();
        }
      });
    },
    onInputExit() {
      this.editMode = false;
    },
    onInputChange(val) {
      this.$emit("inputChange", val);
    }
  }
};
</script>
<style lang="less" scoped>
#SuperTable{
  #edit-cell{
    .edit-input {
      position: absolute;
      left: 6px;
      top: 6px;
      height: calc(100% - 12px);
      width: calc(100% - 12px);
      /deep/.el-input__inner{
        border-left-color: #dddddd  !important;
        border-right-color: #dddddd  !important;
        border-top-color: #dddddd  !important;
        height: 100% !important;
        text-align: center;
      }
    }  
  }
}
  
</style>